<template>
  <div class="layout-container mb-48 md:mb-64">
    <div class="mx-auto">
      <div v-if="model.label" class="bg-blue text-xsmall-medium rounded-full py-4 px-8 sm:px-16 sm:py-6 mb-16 w-fit-content sm:mb-24">
        {{ model.label }}
      </div>
      <h1
        v-if="model.title"
        class="text-heading-2xl mb-16 sm:mb-24"
      >
        {{ model.title }}
      </h1>
      <div class="border-t border-grey400">
        <div
          v-for="(item, index) in model.items"
          :key="index"
          class="border-b border-grey400"
        >
          <div
            class="cursor-pointer py-16 flex items-center justify-between sm:py-24"
            @click="toogleExpanded(index)"
          >
            <h2 class="font-dinPro font-medium mb-12 text-small-medium mb-0">{{ item.title }}</h2>
            <div class="relative h-24">
              <i class="text-lg sm:text-xl inline-block absolute right-0 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-16 h-16"
                >
                  <path
                    v-if="expanded.includes(index)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5,12h-15"
                  />
                  <path
                    v-else
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </i>
            </div>
          </div>
          <transition>
            <div
              class="pb-24 sm:pb-32"
              :class="{
                hidden: !expanded.includes(index)
              }"
            >
              <div class="wysiwyg wysiwyg--sm-text" v-html="item.text" />
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div v-if="isEpiEdit" class="text-center">
      <div
        v-epi-edit="'items'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Edit items
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { FaqBlock } from '~/content-types';
import { type Ref, ref } from 'vue';

import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';
const { isEpiEdit } = storeToRefs(usePageContentStore());

const props = defineProps<{
  model: FaqBlock;
}>();

const expanded: Ref<number[]> = ref([]);

const toogleExpanded = (index: number) => {
  if (expanded.value.includes(index)) {
    expanded.value = expanded.value.filter(i => i !== index);
  } else {
    expanded.value.push(index);
  }
};

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: props.model?.items?.map((item) => {
    return {
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.text,
      },
    };
  }),
};

useJsonld(jsonLd);

/* 
useHead({
  script: [
    {
      type: 'application/ld-json',
      children: JSON.stringify(jsonLd),
    },
  ],
}); */

</script>
